import { StyleSheet } from "react-native";
export const linkStyle = StyleSheet.create({
  link: {
    fontFamily: "Open Sans",
    fontSize: 24,
    fontWeight: "600",
    lineHeight: 32,
    textDecorationLine: "underline",
    textUnderlinePosition: "from-font"
  }
});