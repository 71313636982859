import { humanize } from "@/lib/helpers";

import { BaseRecord } from "../base/types";
import { Door } from "../door/types";
import { Panel } from "../panel/types";

export interface Reader extends BaseRecord {
  panel_id: number;
  panel: Panel;
  name: string;
  reader_number: number;
  synced_at: string;
  mode: string;
  led_mode: string;
  keypad_mode: KeypadMode;
  led_drive_mode: LedDriveMode;
  osdp_flags: OSDPFlags;
  dt_fmt: DataFormat[];
  status: string;
  led_color: LEDColor;
  submit_key: string;
  pin_digits: number;
  door?: Door | null;
  address: number;
  original_address?: number;
  firmware_version?: string;
  model?: string;
  serial?: string;
  vendor?: string;
  version?: string;
  compatible_reader_files?: Array<{
    name: string;
    id: string;
  }>;
  reader_file_histories?: Array<{
    name: string;
    inserted_at: string;
  }>;
  // these fields are only used for the form, we will not store them in the database
  osdp_flags_address?: OSDPFlags["address"];
  osdp_flags_auto_discovery_disabled?: OSDPFlags["auto_discovery_disabled"];
  osdp_flags_baud?: OSDPFlags["baud"];
  osdp_flags_secure?: OSDPFlags["secure"];
  osdp_flags_tracing?: OSDPFlags["tracing"];
  dt_fmt_wiegand_pulses?: boolean;
}

// TODO: Add OSDP doc link here to explain the options better
export type OSDPFlags = {
  address: number;
  auto_discovery_disabled: boolean;
  baud: number;
  secure: boolean;
  tracing: boolean;
};

export enum ReaderProtocols {
  wiegand = "wiegand",
  osdp = "osdp",
}

export const readerProtocolOptions = [
  { label: humanize(ReaderProtocols.wiegand), value: ReaderProtocols.wiegand },
  { label: ReaderProtocols.osdp.toUpperCase(), value: ReaderProtocols.osdp },
];

enum KeypadMode {
  not_defined = "not_defined",
  mr20_8bit_with_tamper = "mr20_8bit_with_tamper",
  hughes_id_4bit = "hughes_id_4bit",
  motorola_indala = "motorola_indala",
  mr20_8bit_without_tamper = "mr20_8bit_without_tamper",
  reserved = "reserved",
  hid_4bit_alive_60_sec = "hid_4bit_alive_60_sec",
  hid_8bit_alive_60_sec = "hid_8bit_alive_60_sec",
  hid_4bit_alive_10_sec = "hid_4bit_alive_10_sec",
  hid_8bit_alive_10_sec = "hid_8bit_alive_10_sec",
}

export const ValidSubmitKeys = [
  "#",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "*",
  "auto",
];

export const SubmitKeyOptions = [
  { label: "#", value: "#" },
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "*", value: "*" },
  { label: "auto", value: "auto" },
];

export enum LEDColor {
  off = "off",
  red = "red",
  green = "green",
  amber = "amber",
  blue = "blue",
  magenta = "magenta",
  cyan = "cyan",
  white = "white",
}

export const LEDColorOptions = [
  { value: LEDColor.off, label: humanize(LEDColor.off) },
  { value: LEDColor.red, label: humanize(LEDColor.red) },
  { value: LEDColor.green, label: humanize(LEDColor.green) },
  { value: LEDColor.amber, label: humanize(LEDColor.amber) },
  { value: LEDColor.blue, label: humanize(LEDColor.blue) },
  { value: LEDColor.magenta, label: humanize(LEDColor.magenta) },
  { value: LEDColor.cyan, label: humanize(LEDColor.cyan) },
  { value: LEDColor.white, label: humanize(LEDColor.white) },
];

// osdp_flags.address uses 2 bits, so there's 4 possible values
export const OsdpAddressOptions = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
];

export const ValidOsdpAddressOptions = [0, 1, 2, 3];

enum OsdpBaudRate {
  zero = 0,
  one = 9600,
  two = 19200,
  three = 38400,
  four = 115200, // mercury uses bit 4 for 115200
  five = 57600,
  six = 230400,
}

export const ValidOsdpBaudRates = [
  OsdpBaudRate.zero,
  OsdpBaudRate.one,
  OsdpBaudRate.two,
  OsdpBaudRate.three,
  OsdpBaudRate.four,
  OsdpBaudRate.five,
  OsdpBaudRate.six,
];

export const OsdpBaudRateOptions = [
  //
  { value: OsdpBaudRate.zero, label: OsdpBaudRate.zero.toLocaleString() },
  { value: OsdpBaudRate.one, label: OsdpBaudRate.one.toLocaleString() },
  { value: OsdpBaudRate.two, label: OsdpBaudRate.two.toLocaleString() },
  { value: OsdpBaudRate.three, label: OsdpBaudRate.three.toLocaleString() },
  { value: OsdpBaudRate.five, label: OsdpBaudRate.five.toLocaleString() },
  // mercury uses bit 4 for 115200 .. so we re-order the options by label
  { value: OsdpBaudRate.four, label: OsdpBaudRate.four.toLocaleString() },
  { value: OsdpBaudRate.six, label: OsdpBaudRate.six.toLocaleString() },
];

export const ValidLedColors = [
  LEDColor.off,
  LEDColor.red,
  LEDColor.green,
  LEDColor.amber,
  LEDColor.blue,
  LEDColor.magenta,
  LEDColor.cyan,
  LEDColor.white,
];

export const KeypadModeOptions = [
  { value: KeypadMode.not_defined, label: humanize(KeypadMode.not_defined) },
  {
    value: KeypadMode.mr20_8bit_with_tamper,
    label: humanize(KeypadMode.mr20_8bit_with_tamper),
  },
  {
    value: KeypadMode.hughes_id_4bit,
    label: humanize(KeypadMode.hughes_id_4bit),
  },
  {
    value: KeypadMode.motorola_indala,
    label: humanize(KeypadMode.motorola_indala),
  },
  {
    value: KeypadMode.mr20_8bit_without_tamper,
    label: humanize(KeypadMode.mr20_8bit_without_tamper),
  },
  { value: KeypadMode.reserved, label: humanize(KeypadMode.reserved) },
  {
    value: KeypadMode.hid_4bit_alive_60_sec,
    label: humanize(KeypadMode.hid_4bit_alive_60_sec),
  },
  {
    value: KeypadMode.hid_8bit_alive_60_sec,
    label: humanize(KeypadMode.hid_8bit_alive_60_sec),
  },
  {
    value: KeypadMode.hid_4bit_alive_10_sec,
    label: humanize(KeypadMode.hid_4bit_alive_10_sec),
  },
  {
    value: KeypadMode.hid_8bit_alive_10_sec,
    label: humanize(KeypadMode.hid_8bit_alive_10_sec),
  },
];

export const ValidKeypadModes = [
  KeypadMode.not_defined,
  KeypadMode.mr20_8bit_with_tamper,
  KeypadMode.hughes_id_4bit,
  KeypadMode.motorola_indala,
  KeypadMode.mr20_8bit_without_tamper,
  KeypadMode.reserved,
  KeypadMode.hid_4bit_alive_60_sec,
  KeypadMode.hid_8bit_alive_60_sec,
  KeypadMode.hid_4bit_alive_10_sec,
  KeypadMode.hid_8bit_alive_10_sec,
];

export enum LedDriveMode {
  generic_1wire_tristate_bicolor = "generic_1wire_tristate_bicolor",
  reserved = "reserved",
  separate_red_green_no_buzzer = "separate_red_green_no_buzzer",
  dorado780_2wire_with_color_conversion = "dorado780_2wire_with_color_conversion",
  lcd_display_model = "lcd_display_model",
  bioscrypt_VStation_Flex = "bioscrypt_VStation_Flex",
  osdp_reader_with_lcd_and_keypad = "osdp_reader_with_lcd_and_keypad",
  snet_reader = "snet_reader",
  handkey_reader = "handkey_reader",
}

export const WiegandLedDriveModeOptions = [
  {
    value: LedDriveMode.generic_1wire_tristate_bicolor,
    label: humanize(LedDriveMode.generic_1wire_tristate_bicolor),
  },
  { value: LedDriveMode.reserved, label: humanize(LedDriveMode.reserved) },
  {
    value: LedDriveMode.separate_red_green_no_buzzer,
    label: humanize(LedDriveMode.separate_red_green_no_buzzer),
  },
  {
    value: LedDriveMode.dorado780_2wire_with_color_conversion,
    label: humanize(LedDriveMode.dorado780_2wire_with_color_conversion),
  },
  {
    value: LedDriveMode.lcd_display_model,
    label: humanize(LedDriveMode.lcd_display_model),
  },
  {
    value: LedDriveMode.bioscrypt_VStation_Flex,
    label: humanize(LedDriveMode.bioscrypt_VStation_Flex),
  },

  {
    value: LedDriveMode.snet_reader,
    label: humanize(LedDriveMode.snet_reader),
  },
  {
    value: LedDriveMode.handkey_reader,
    label: humanize(LedDriveMode.handkey_reader),
  },
];

export const WiegandLedDriveModes = [
  LedDriveMode.generic_1wire_tristate_bicolor,
  LedDriveMode.reserved,
  LedDriveMode.separate_red_green_no_buzzer,
  LedDriveMode.dorado780_2wire_with_color_conversion,
  LedDriveMode.lcd_display_model,
  LedDriveMode.bioscrypt_VStation_Flex,
  LedDriveMode.snet_reader,
  LedDriveMode.handkey_reader,
];

export enum DataFormat {
  wiegand_pulses = "wiegand_pulses",
  trim_zero_bits = "trim_zero_bits",
  mag_stripe_track_2_data_decode = "mag_stripe_track_2_data_decode",
  allow_bi_directional_mag_decode = "allow_bi_directional_mag_decode",
  allow_northern_mag_decode = "allow_northern_mag_decode",
  enable_F_2F_decoding = "enable_F_2F_decoding",
  supervision_enabled = "supervision_enabled",
  inputs_from_reader = "inputs_from_reader",
}

export const DataFormatOptions = [
  {
    value: DataFormat.wiegand_pulses,
    label: humanize(DataFormat.wiegand_pulses),
  },
  {
    value: DataFormat.trim_zero_bits,
    label: humanize(DataFormat.trim_zero_bits),
  },
  {
    value: DataFormat.mag_stripe_track_2_data_decode,
    label: humanize(DataFormat.mag_stripe_track_2_data_decode),
  },
  {
    value: DataFormat.allow_bi_directional_mag_decode,
    label: humanize(DataFormat.allow_bi_directional_mag_decode),
  },
  {
    value: DataFormat.allow_northern_mag_decode,
    label: humanize(DataFormat.allow_northern_mag_decode),
  },
  {
    value: DataFormat.enable_F_2F_decoding,
    label: humanize(DataFormat.enable_F_2F_decoding),
  },
  {
    value: DataFormat.supervision_enabled,
    label: humanize(DataFormat.supervision_enabled),
  },
  {
    value: DataFormat.inputs_from_reader,
    label: humanize(DataFormat.inputs_from_reader),
  },
];

export enum ReaderVendors {
  Wavelynx = "wavelynx",
  Nedap = "nedap",
}

export const HumanReadableReaderVendors: {
  [vendor: ReaderVendors | string]: string;
} = {
  [ReaderVendors.Wavelynx]: "Wavelynx",
  [ReaderVendors.Nedap]: "Nedap",
};

const ReaderVendorModels = {
  [ReaderVendors.Wavelynx]: {
    Ethos: "ethos",
  },
  [ReaderVendors.Nedap]: {
    reach: "reach",
  },
} as const;

export type ReaderModel =
  (typeof ReaderVendorModels)[keyof typeof ReaderVendorModels][keyof (typeof ReaderVendorModels)[keyof typeof ReaderVendorModels]];

export const HumanReadableReaderModels: {
  [model: ReaderModel | string]: string;
} = {
  ethos: "Ethos",
  reach: "REACH",
};

export const getReaderModelOptionsForVendor = (vendor?: ReaderVendors) => {
  if (!vendor) {
    return null;
  }

  return Object.entries(ReaderVendorModels[vendor]).map(([key, value]) => ({
    value,
    label: HumanReadableReaderModels[value],
  }));
};

export const ReaderVendorOptions = Object.values(ReaderVendors).map(
  (vendor) => ({
    value: vendor,
    label: HumanReadableReaderVendors[vendor],
  })
);

export const ReaderModelOptions = Object.values(ReaderVendorModels).flatMap(
  (vendorModels) =>
    Object.values(vendorModels).map((model) => ({
      value: model,
      label: HumanReadableReaderModels[model],
    }))
);
