import { BaseRecord } from "../base/types";
import { Controller } from "../controller/types";
import { Input } from "../input/types";
import { Reader } from "../reader/types";
import { Schedule } from "../schedule/types";
import { Floor } from "../floor/types";
import { Output } from "../output/types";

export interface Door extends BaseRecord {
  access_levels_schedules?: Schedule[];
  battery?: number | string;
  battery_updated?: string;
  configuration?: string;
  firmware?: SchlageFirmware;
  controller: Controller;
  controller_id: number;
  default_mode: DoorMode;
  door_number?: number;
  enabled?: boolean;
  floors?: Floor[];
  inserted_at: string;
  locked?: boolean;
  mode?: DoorMode;
  lockdown: boolean;
  mode_exp?: string;
  name?: string;
  pending_delete?: string;
  pending_pin_codes: number;
  reader?: Reader;
  reader_id?: number;
  remote_id?: string;
  rex1?: Input;
  rex1_id?: number;
  rex2?: Input;
  rex2_id?: number;
  serial_number?: string;
  strike?: Output;
  strike_id?: number;
  strike_max?: number;
  strike_min?: number;
  strike_mode?: StrikeModes | number;
  synced_at: string;
  unlocked_schedule: Schedule;
  unlocked_schedule_id?: number;
  used_pin_codes: number;
  master_code?: string;
  held_open_limit_seconds: number;
  disable_forced_open_events: boolean;
  dsm1_id?: number;
}

export enum DoorMode {
  disabled = "disabled",
  unlocked = "unlocked",
  locked = "locked",
  facility_code_only = "facility_code_only",
  card_only = "card_only",
  pin_only = "pin_only",
  card_or_pin = "card_or_pin",
  lockdown = "lockdown",
}

export const ValidDoorModes = [
  DoorMode.disabled,
  DoorMode.unlocked,
  DoorMode.locked,
  DoorMode.facility_code_only,
  DoorMode.card_only,
  DoorMode.pin_only,
  DoorMode.card_or_pin,
  DoorMode.lockdown,
];

export const DoorModeOptions = [
  { label: "Disabled", value: DoorMode.disabled },
  { label: "Unlocked", value: DoorMode.unlocked },
  { label: "Locked", value: DoorMode.locked },
  { label: "Facility Code Only", value: DoorMode.facility_code_only },
  { label: "Card Only", value: DoorMode.card_only },
  { label: "Pin Only", value: DoorMode.pin_only },
  { label: "Card Or Pin", value: DoorMode.card_or_pin },
];

// Define a mapping object for door modes and their corresponding assistive texts
export const DoorModeAssistiveTextMap = {
  [DoorMode.disabled]: "ACR Disabled",
  [DoorMode.unlocked]: "Unlimited Access",
  [DoorMode.locked]: "No Access (REX Active)",
  [DoorMode.facility_code_only]: "",
  [DoorMode.card_only]: "",
  [DoorMode.pin_only]: "",
  [DoorMode.card_or_pin]: "",
  [DoorMode.lockdown]: "",
};

export const getDoorModeAssistiveText = (mode?: DoorMode) =>
  mode ? DoorModeAssistiveTextMap[mode] : "";

export interface SchlageFirmware {
  lock: string;
  main: string;
  mainBl: string;
  ble: string;
}

export enum StrikeModes {
  none = "none",
  open = "open",
  close = "close",
  tailgate = "tailgate",
}

export const StrikeModeOptions = [
  { label: "None", value: StrikeModes.none },
  { label: "Open", value: StrikeModes.open },
  { label: "Close", value: StrikeModes.close },
  { label: "Tailgate", value: StrikeModes.tailgate },
];

export enum DoorConfigurations {
  single = "single",
  elevatorWithFloor = "elevator_with_floor",
  elevatorWithoutFloor = "elevator_no_floor",
}

export const DoorConfigurationOptions = [
  { label: "Single", value: DoorConfigurations.single },
  {
    label: "Elevator With Feedback",
    value: DoorConfigurations.elevatorWithFloor,
  },
  {
    label: "Elevator No Feedback",
    value: DoorConfigurations.elevatorWithoutFloor,
  },
];

export const ElevatorConfigurationOptions = [
  {
    label: "Elevator With Feedback",
    value: DoorConfigurations.elevatorWithFloor,
  },
  {
    label: "Elevator No Feedback",
    value: DoorConfigurations.elevatorWithoutFloor,
  },
];

export const ElevatorFeedbackOptions = [
  { label: "Yes", value: DoorConfigurations.elevatorWithFloor },
  {
    label: "No",
    value: DoorConfigurations.elevatorWithoutFloor,
  },
];

export const ValidDoorConfigurations = [
  DoorConfigurations.single,
  DoorConfigurations.elevatorWithFloor,
  DoorConfigurations.elevatorWithoutFloor,
];

export const ElevatorConfigurations = [
  DoorConfigurations.elevatorWithFloor,
  DoorConfigurations.elevatorWithoutFloor,
];
export interface MemberDoors {
  member_id?: number;
  door_ids?: string[];
}

export interface DoorModeRequest {
  id: number;
  mode?: DoorMode;
  default_mode?: DoorMode;
  duration?: number;
}

export const TempModeDurationOptions = [
  { label: "Reset", value: 0 },
  { label: "1 Minute", value: 1 },
  { label: "15 Minutes", value: 15 },
  { label: "30 Minutes", value: 30 },
  { label: "45 Minutes", value: 45 },
  { label: "1 Hour", value: 60 },
  { label: "1.5 Hours", value: 90 },
  { label: "2 Hour", value: 120 },
];

export const isElevator = ({ configuration }: Door) => {
  return configuration !== DoorConfigurations.single;
};
